import React from 'react'
import { Link } from 'react-router-dom';

const Navbar = ({ titr, to }) => {
	return (
		<div className='w-full h-20 left-0 top-0 fixed z-30 flex items-center justify-center'>
			<div className='w-full h-full flex justify-center items-center text-fontSize_24 text-text_color bg-navbar_color text-center shadow-xl md:w-[50%] lg:w-[40%] xl:w-[35%]'>
				<h1 className='w-64 h-full flex justify-center items-center font-bold'>
					{titr}
				</h1>
			</div>
		</div>

	)
}

export default Navbar;