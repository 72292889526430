import React, { useMemo, useState } from 'react';
import { Route, Routes, } from 'react-router-dom';

//------------------------------------------components--------------------------------------------
import Home from './screens/home/Home';
import ConfirmPrPage from './screens/present_form/ConfirmPrPage';
import { AppContext } from './contexts/AppContext';

//------------------------------------------components--------------------------------------------

const App = () => {

  //------------------------------------------global_state-----------------------------------------
  const [saveFiled, setSaveFiled] = useState([]);
  //------------------------------------------global_state-----------------------------------------


  //------------------------------------------hook-------------------------------------------------
  const appContextValues = useMemo(() => {
    return {
      saveFiled,
      setSaveFiled
    }
  }, [saveFiled]);
  //------------------------------------------hook------------------------------------------------

  //------------------------------------------app-------------------------------------------------
  return (
    <AppContext.Provider value={appContextValues}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/createPage" element={<ConfirmPrPage />} />
      </Routes>
    </AppContext.Provider>
  );
}
//------------------------------------------app--------------------------------------------------

export default App;

