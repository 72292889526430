import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ThreeDot } from 'react-loading-indicators';

//------------------------------------------import_components------------------------------------------
import Input_custom from '../../components/Input';
import Button_custom from '../../components/Button';
import Navbar from '../../components/Navbar';
import { AppContext } from '../../contexts/AppContext';
import { apiURLs } from '../../services/apiURL';
//------------------------------------------import_components------------------------------------------


const ConfirmPrPage = () => {
  //------------------------------------------global_state---------------------------------------------
  const { setSaveFiled } = useContext(AppContext);
  //------------------------------------------global_state---------------------------------------------

  const navigate = useNavigate();

  //------------------------------------------state---------------------------------------------------
  const [entrance, setEntrance] = useState('');
  const [left, setLeft] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nationalCode, setNationalCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [liveCity, setLiveCity] = useState('');
  const [job, setJob] = useState('');
  const [education, setEducation] = useState('');
  const [favorite, setFavorite] = useState('');
  const [reservationRoom, setReservationRoom] = useState('');
  const [birthday, setBirthday] = useState('');
  const [loading, setLodaing] = useState(false);
  const [error, setError] = useState(false);
  //------------------------------------------state---------------------------------------------------


  //------------------------------------------functions-----------------------------------------------
  const handleFormSubmit = async () => {
    const newData = {
      entrance: entrance,
      left: left,
      name: name,
      lastName: lastName,
      nationalCode: nationalCode,
      birthday: birthday,
      phoneNumber: phoneNumber,
      liveCity: liveCity,
      job: job,
      education: education,
      favorite: favorite,
      reservationRoom: reservationRoom
    };

    if (name.length === 0) {
      setError(true);
      return null;
    }

    setLodaing(true);

    try {

      const headres = {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': '1',
      }

      const response = await axios.post(
        `${apiURLs.serverURL}/create-passengers`,
        newData,
        {
          headers: headres
        }
      );

      if (response.status === 201) {
        setSaveFiled(prev => [...prev, newData]);
        navigate('/');
        setLodaing(false);
      } else {
        alert(' نتتو چک کن! ');
      }


    } catch (error) {
      console.error('Error updating Excel file:', error);
      setLodaing(false);
    }
  };
  //------------------------------------------functions-----------------------------------------------


  //------------------------------------------view_component------------------------------------------
  return (
    <div className='bg-background_color w-full h-dvh flex justify-center overflow-hidden pt-20'>
      <div className='bg-background_color w-full h-dvh overflow-hidden px-2 md:w-[50%] lg:w-[40%] xl:w-[35%]' dir='rtl'>

        <div className='navbar createfrom flex items-center' dir='ltr'>

          <Link className='fixed left-6 top-6 z-50'
            to={'/'}
          >
            <md-filled-icon-button id='backButton_Custom'>
              <md-icon>keyboard_arrow_left</md-icon>
            </md-filled-icon-button>

          </Link>


          <Navbar
            titr={' تایید اطلاعات '}
          />

        </div>

        <div className='scroll_hide body w-full h-full pb-20 px-2 overflow-y-scroll'>

          <Input_custom
            placeholder={' تاریخ ورود '}
            input_label={' تاریخ ورود '}
            value={entrance}
            onChange={(event) => setEntrance(event.target.value)}
          />

          <Input_custom
            placeholder={' تاریخ خروج '}
            input_label={' تاریخ خروج '}
            value={left}
            onChange={(event) => setLeft(event.target.value)}
          />

          <Input_custom
            placeholder={' نام '}
            input_label={' نام '}
            error={error}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />

          <Input_custom
            placeholder={' نام خانوادگی '}
            input_label={' نام خانوادگی '}
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />

          <Input_custom
            placeholder={' کد ملی '}
            input_label={' کد ملی '}
            type={'number'}
            value={nationalCode}
            onChange={(event) => setNationalCode(event.target.value)}
          />

          <Input_custom
            placeholder={' تاریخ تولد '}
            input_label={' تاریخ تولد '}
            value={birthday}
            onChange={(event) => setBirthday(event.target.value)}
          />

          <Input_custom
            placeholder={' شماره تماس '}
            input_label={' شماره تماس '}
            type={'number'}
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
          />

          <Input_custom
            placeholder={' شهر سکونت '}
            input_label={' شهر سکونت '}
            value={liveCity}
            onChange={(event) => setLiveCity(event.target.value)}
          />

          <Input_custom
            placeholder={' شغل '}
            input_label={' شغل '}
            value={job}
            onChange={(event) => setJob(event.target.value)}
          />

          <Input_custom
            placeholder={' تحصیلات '}
            input_label={' تحصیلات '}
            value={education}
            onChange={(event) => setEducation(event.target.value)}
          />

          <Input_custom
            placeholder={' علایق '}
            input_label={' علایق '}
            value={favorite}
            onChange={(event) => setFavorite(event.target.value)}
          />

          <Input_custom
            placeholder={' اتاق رزرو '}
            input_label={' اتاق رزرو '}
            value={reservationRoom}
            onChange={(event) => setReservationRoom(event.target.value)}
          />

          <div className='footer flex items-center justify-center mb-4 mt-16'>
            {
              loading ?
                <ThreeDot variant="bounce" color="#32cd32" size="medium" text="" textColor="" />
                :
                <Button_custom
                  button_className={'bg-button_color w-32 h-14 rounded-full text-black text-white text-fontSize_16'}
                  button_text={' تایید '}
                  onClick={handleFormSubmit}
                />
            }
          </div>

        </div>

      </div>
    </div>
  )
}
//------------------------------------------view_component------------------------------------------

export default ConfirmPrPage
