import React, { useContext} from 'react'
import { Link } from 'react-router-dom';

//------------------------------------------import_components-------------------------------------------
import OpacityAnimetedPage from './../../components/animation/SetOpacityAnimatedPage';
import { AppContext } from '../../contexts/AppContext';
import Button_custom from '../../components/Button';
import Navbar from '../../components/Navbar';
import './style/home.css';
//------------------------------------------import_components-------------------------------------------


const Home = () => {

	//------------------------------------------global_state---------------------------------------------
	const { saveFiled } = useContext(AppContext);
	//------------------------------------------global_state---------------------------------------------

	//------------------------------------------view_component-------------------------------------------
	return (
		<OpacityAnimetedPage motion_div_className={'bg-background_color w-full h-dvh flex justify-center overflow-hidden '}>

			{/* <img
				className='fixed w-dvh h-full object-cover blur-sm'
				src={require('../../assets/فایل جدید.png')}
				alt="shape.jpg"
			/> */}

			<div className='container h-full md:w-[50%] lg:w-[40%] xl:w-[35%] overflow-hidden pt-24'>

				<Navbar
					titr={' گیلانه جان '}
				/>


				<div className='scroll_hide w-full h-full flex flex-col gap-8 px-10 overflow-y-scroll pb-56 pt-4' dir='rtl'>
					{
						saveFiled.reverse().map((item, index) => (

							<div className='w-full h-36 p-2 flex items-center justify-between gap-2 bg-white/30 border border-white rounded-3xl backdrop-blur-3xl shadow-lg'
								key={index}
							>
								<div className='flex flex-col justify-center gap-8 w-1/2 h-32 text-center rounded-3xl'>

									<div
										className='w-full flex flex-col items-center justify-center text-fontSize_12 line-clamp-3'
									>
										نام نام خانوادگی :
										<p className='flex gap-2 text-fontSize_14 font-bold '>
											<span className='text-card_text'>{item.name}</span>
											<span className='text-card_text'>{item.lastName}</span>
										</p>
									</div>

									<p
										className=' w-full flex flex-col text-fontSize_12 line-clamp-3'
									>
										اتاق رزرو :
										<span className='text-card_text text-fontSize_14 font-bold'>{item.reservationRoom}</span>
									</p>
								</div>

								<div className='flex flex-col justify-center gap-8 w-1/2 h-32 text-center rounded-3xl text-fontSize_12 line-clamp-3'>
									<p
										className=' w-full flex flex-col'
									>
										تاریخ ورود :
										<span className='text-card_text text-fontSize_14 font-bold'>{item.entrance}</span>
									</p>
									<p
										className=' w-full flex flex-col'
									>
										تاریخ خروج :
										<span className='text-card_text text-fontSize_14 font-bold'>{item.left}</span>
									</p>

								</div>

							</div>
						))
					}
				</div>

				<div className='flex items-center justify-center absolute bottom-16 right-6 overflow-hidden'>
					<Link
						className='footer w-auto'
						to={'/createPage'}
					>

						<Button_custom
							button_className={'bg-button_color w-16 h-16 rounded-xl text-black'}
							button_element={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={2}
									stroke="currentColor"
									className="size-8"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M12 4.5v15m7.5-7.5h-15"
									/>
								</svg>
							}
						/>

					</Link>
				</div>

			</div>
		</OpacityAnimetedPage>
	)
}
//------------------------------------------view_component-------------------------------------------
export default Home;