import React from 'react'
import './style/style.css'
import { Button } from '@mui/material'
const Button_custom = ({ button_text, onClick, button_className, button_element, button_size }) => {
	return (
		<>
			<Button
				className={button_className}
				onClick={onClick}
				variant="contained"
				size={button_size}
			>
				<div>{button_element}</div>
				{button_text}
			</Button>
		</>
	)
}

export default Button_custom